import React, {Component, useEffect} from 'react';
import {inject, observer} from 'mobx-react';

import {LoginWrapper, Caption, SignInView, TermsConditionsLabel, TermsConditionsLink, CantViewContent} from './styles';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import CenteredMessage from 'components/Layout/CenteredMessage';

import {termsAndConditionsUrl} from 'shared/constants';

import messages from './messages';
import {DOMAIN_LOGIN_OPTIONS} from 'shared/enums';
import snakeCase from 'lodash/snakeCase';
import views from 'config/views';

import EmailAndPasswordLogin from 'shared/components/EmailAndPasswordLogin';
import PhoneAndPasswordLogin from 'shared/components/PhoneAndPasswordLogin';

export const SignInViewOptions = observer(({loginType, store, children}) => {
  const {auth, authLogin, router} = store;

  useEffect(() => {
    return () => {
      auth.domainLoginFormPhoneNumberPassword?.reset();
      auth.domainLoginFormEmailPassword?.reset();
      authLogin.setErrorResponse(null);
    };
  }, []);

  switch (snakeCase(loginType).toUpperCase()) {
    case DOMAIN_LOGIN_OPTIONS.PHONE_NUMBER:
      return (
        <PhoneAndPasswordLogin
          form={auth.domainLoginFormPhoneNumberPassword}
          onSubmit={auth.submitLoginFormPhonePassword}
          isLoading={auth.loading}
          forgotPasswordLink={window.location.origin + views.requestResetPasswordPhone.path}
          onForgotPasswordClick={() => router.goTo(views.requestResetPasswordPhone, {})}
          onAllSignInOptionsClick={() => router.goTo(views.domainLogin, {}, {redirect: router.queryParams?.redirect})}
          isOnlyOneLoginOption={auth.isOnlyOneLoginOption}
          errorResponse={authLogin.errorResponse}
          showErrorMessage={authLogin.showErrorMessage}
        />
      );
    case DOMAIN_LOGIN_OPTIONS.EMAIL:
    case DOMAIN_LOGIN_OPTIONS.DOMAIN_PASSWORD:
      // TODO: currently <EmailAndPasswordLogin /> includes workspace password aswell
      return <EmailAndPasswordLogin />;
    default:
      return children;
  }
});

@inject('store')
@observer
class SignInViewComponent extends Component {
  render() {
    const {
      store: {
        auth: {loggedInButCantViewContent, user},
        router: {params}
      }
    } = this.props;

    const loginContent = loggedInButCantViewContent ? (
      <CantViewContent>
        <FormattedMessage {...messages.message_cantViewContent} />
      </CantViewContent>
    ) : (
      <React.Fragment>
        <Caption>
          <FormattedMessage {...messages.signInToYourWorkspace} />
        </Caption>
        <SignInViewOptions loginType={params.loginType} store={this.props.store} intl={this.props.intl}>
          {this.props.children}
        </SignInViewOptions>
        <TermsConditionsLabel>
          <FormattedMessage
            {...messages.messages_byUsingOurService}
            values={{
              termsAndPolicyLink: (
                <TermsConditionsLink href={termsAndConditionsUrl} target="_blank">
                  <FormattedMessage {...messages.termsAndConditionsAndPrivacyPolicy} />
                </TermsConditionsLink>
              )
            }}
          />
        </TermsConditionsLabel>
      </React.Fragment>
    );

    return (
      <SignInView>
        {user ? (
          <CenteredMessage icon={'user'}>
            <FormattedMessage {...messages.message_youAreAlreadyLoggedIn} />
          </CenteredMessage>
        ) : (
          <LoginWrapper>{loginContent}</LoginWrapper>
        )}
      </SignInView>
    );
  }
}

export default SignInViewComponent;
